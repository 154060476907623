import React, { PureComponent } from 'react'
import forEach from 'lodash/forEach'
import { object } from 'prop-types'

class CSSVariableApplicator extends PureComponent {
  componentDidMount() {
    this.updateCSSVariables(this.props.variables);
  }

  componentDidUpdate(prevProps) {
    if (this.props.variables !== prevProps.variables) {
      this.updateCSSVariables(this.props.variables);
    }
  }

  updateCSSVariables(variables) {
    forEach(variables, (value, prop) => {
      document.documentElement.style.setProperty(prop, value)
    })
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

CSSVariableApplicator.propTypes = {
  variables: object
}

export default CSSVariableApplicator
