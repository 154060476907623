import config from './config'

export async function fetchMetadata(id) {
  const params = {
    url: config.baseUrls.cdn + config.folders.metadata + '/' + id + '.json',
    options: {
      method: 'GET',
      headers: {
        'Cache-Control': 'private, no-cache, no-store, max-age=0'
      }
    }
  }

  return fetch(params.url, params.options)
    .then(response => {
      if (!response.ok) {
        return Promise.resolve({})
      }

      return response.json()
        .then(r => Promise.resolve(r))
    })
}

export async function fetchAppInfo(id) {
  const params = {
    url: config.baseUrls.cdn + config.folders.store + '/' + id + '.json',
    options: {
      method: 'GET',
      headers: {
        'Cache-Control': 'private, no-cache, no-store, max-age=0'
      }
    }
  }

  return fetch(params.url, params.options)
    .then(response => {
      if (!response.ok) {
        return Promise.resolve({})
      }

      return response.json()
        .then(r => Promise.resolve(r))
    })
}

export async function fetchTikTokEmbed (url) {
  return fetch(`${config.baseUrls.tiktok}?url=${url}`)
    .then(response => {
      if (!response.ok) {
        return Promise.reject({error: 'Error: can\'t load the video'})
      }

      return response.json()
        .then(r => Promise.resolve(r))
    })
}
