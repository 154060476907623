import React, {Fragment, PureComponent} from 'react'
import { string, array, bool, object } from 'prop-types'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'
import URL from 'url-parse'

import {A, Avatar, Text, Button, Loading, Icon, SpotIllustration, Modal, ModalContent} from '@rebrandly/styleguide'
import {fetchTikTokEmbed} from './utils/api'

import './LinkGalleryContent.css'

const SOCIALS = [
  { name: 'website', icon: 'ic-favicon' },
  { name: 'email', icon: 'ic-mail', baseUrl: 'https://middleware.rebrandly.com/v1/mail/compose?rb-dashboard-composer=mailto&to=' },
  { name: 'instagram', icon: 'ic-social-instagram-nofill' },
  { name: 'youtube', icon: 'ic-social-youtube-nofill' },
  { name: 'facebook', icon: 'ic-social-facebook-nofill' },
  { name: 'linkedin', icon: 'ic-social-linkedin-nofill' },
  { name: 'twitter', icon: 'ic-social-twitter-nofill' },
  { name: 'whatsapp', icon: 'ic-social-whatsapp-nofill', baseUrl: 'https://wa.me/' }
]
const tiktokBaseUrl = 'https://www.tiktok.com/embed'

function addProtocolToUrl (shorturl, https) {
  const url = new URL(shorturl, {})
  url.set('protocol', https ? 'https:' : 'http:')
  return url.href
}

function parseTikTok (url = '') {
  const tiktokFormatRegex = /https:\/\/(?:m|www|vm)?\.?tiktok\.com\/@[^/]+\/video\/(\d+)/

  return url.match(tiktokFormatRegex)
}

const INITIAL_STATE = { loading: false, open: false, embed: null, error: null, link: null }
class LinkGalleryContent extends PureComponent {
  state = {
    tiktokModal: INITIAL_STATE
  }

  setTikTokState = (newPartialState, callback) => {
    if (newPartialState) {
      this.setState({ tiktokModal: { ...this.state.tiktokModal, ...newPartialState } }, callback)
    } else {
      this.setState({ tiktokModal: INITIAL_STATE }, callback)
    }
  }

  buildSocialArray = () => SOCIALS.filter(s => this.props.socials[s.name])
      .map(staticInfo => (
        {
          ...staticInfo,
          ...this.props.socials[staticInfo.name]
        }
      ))

  openTikTokModal = (link) => {
    this.setTikTokState(
      { open: true, loading: true, link },
      () => fetchTikTokEmbed(link.destination)
        .then(response => {
          if (get(response,  'embed_product_id')) {
            this.setTikTokState({
              loading: false,
              embed: {
                src: `${tiktokBaseUrl}/${response['embed_product_id']}`,
                title: get(response,  'title')
              }
            })
          } else {
            throw new Error('No valid ID')
          }
        })
        .catch(({error = null}) => {
          this.setTikTokState({ loading: false, error })
        })
      )
  }
  closeTikTokModal = () => {
    this.setTikTokState({ open: false })
    setTimeout(this.setTikTokState, 250) // @dev avoid to see empty state during closing animation
  }

  render () {
    const hasUserInfo = this.props.avatar.src && this.props.username.value
    const socialArray = this.buildSocialArray()

    const getInfo = social => {
      switch (social.name) {
        case 'email':
          return {
            ...social,
            href: social.baseUrl + btoa(social.value)
          }

        case 'whatsapp':
          return {
            ...social,
            href: social.baseUrl + social.value.replace('+', '')
          }

        default:
          return {
            ...social,
            href: social.value
          }
      }
    }

    const {shortUrl, https} = this.props.avatar.anchor

    return (
      <div className='App'>
        <div className='App__content Page flex-column'>

          <div className='LinkGallery__content Container--narrow flex-column'>
            {this.props.isLoading && !hasUserInfo
              ? <Loading size='lg' direction='vertical' label='Loading data, please wait...' />
              : <Fragment>
                <div className='flex-column center'>
                  {this.props.avatar.display &&
                    <div className='center-row'>
                      <A href={addProtocolToUrl(shortUrl, https)} disabled={!shortUrl} target='_blank'>
                        <Avatar img={this.props.avatar.src} profile />
                      </A>
                    </div>
                  }
                  {this.props.username.display &&
                    <Text className='Username center-row m-t-16' size='small'>{`@${this.props.username.value}`}</Text>
                  }
                </div>

                {this.props.isLoading
                  ? <div className='m-t-48'>
                    <Loading size='md' label='Loading, please wait...'/>
                  </div>
                  : <Fragment>
                    {!isEmpty(socialArray) &&
                    <div className='LinkGallery__content--social-icons flex center-xs m-t-36'>
                      {socialArray.map(s => {
                        const social = getInfo(s)

                        return (
                          <A key={social.name} href={social.href} target='_blank'>
                            <Icon
                              className={social.name}
                              name={social.icon}
                            />
                          </A>
                        )
                      })}
                    </div>
                    }

                    <div className='center-row m-t-36 flex-column block-xs'>

                      {this.props.links.filter(l => l.active).map(link => {
                        if (link.embedSrc) {
                          return (
                            <div className='VideoWrapper flex-column m-t-8'>
                              <iframe
                                src={link.embedSrc}
                                title={link.title}
                                style={{ border: 'none' }}
                                allowFullScreen
                              />
                            </div>
                          )
                        }

                        let isTikTokVideo = parseTikTok(link.destination)
                        let buttonProps = {}

                        if (isTikTokVideo) {
                          buttonProps = {
                            ...buttonProps,
                            onClick: () => this.openTikTokModal(link)
                          }
                        } else {
                          buttonProps = {
                            ...buttonProps,
                            href: 'https://' + link.shortUrl
                          }
                        }

                        return (
                          <A className='ellipsis' target='_blank' {...buttonProps} key={link.shortUrl}>
                            <Button
                              className={classNames('m-t-8 ellipsis', {
                                'LinkGallery__content--buttons-squared': !this.props.roundedCorners,
                                'LinkGallery__content--buttons-filled': !this.props.transparentButtons,
                                'LinkGallery__content--buttons-transparent': this.props.transparentButtons
                              })}
                              label={link.title}
                              size='lg'
                              block
                            />
                          </A>
                        )
                      })}

                    </div>
                  </Fragment>
                }

                <Modal className='TikTokModal' isOpen={get(this.state.tiktokModal, 'open', false)} size='sm' onClose={this.closeTikTokModal} showClose>
                  <ModalContent>
                    <div className='TikTokVideo__title'>
                      <Text className="Text-Title">{get(this.state.tiktokModal, 'link.title', '')}</Text>
                    </div>

                    <div className='TikTokVideo__wrapper flex-column space-between overflow-hidden'>
                      <div className='TikTokVideo__content'>
                        {this.state.tiktokModal.loading
                          ? <div className="LoaderPlaceholder TikTokLoader"/>
                          : <>
                          {this.state.tiktokModal.embed &&
                              <div className='flex' style={{ height: '721px', width: '100%', overflow: 'auto' }}>
                                <iframe
                                  title={this.state.tiktokModal.embed.title}
                                  src={this.state.tiktokModal.embed.src}
                                  width="100%"
                                  height="100%"
                                  style={{ border: 'none' }}
                                />
                              </div>
                            }
                            {(!get(this.state.tiktokModal, 'embed', '') || get(this.state.tiktokModal, 'error')) &&
                              <div className="text-center m-b-24">
                                <Text className="Text--Detail" size="small">Sorry, something went wrong.<br/>We can't show the video preview.</Text>
                                <Text className="Text--SubDetail" size="x-small">Click the button below to open it in a new tab</Text>
                              </div>
                            }
                          </>
                        }
                      </div>

                      <div className="TikTokVideo__buttons flex center-xs">
                        <A href={'https://' + get(this.state.tiktokModal, 'link.shortUrl', '')} target="_blank">
                          <Button label="View in TikTok" theme="primary" />
                        </A>
                      </div>
                    </div>
                  </ModalContent>
                </Modal>
              </Fragment>
            }

          </div>


          {!this.props.hideWatermark &&
            <div className="center footer flex">
              <div>
                <A className='flex' href='https://rbnd.bio/gallery' target='_blank'>
                  <SpotIllustration name='ill-spot-linkgallery-by-rb' />
                </A>
              </div>
            </div>
          }

        </div>
      </div>
    )
  }
}

LinkGalleryContent.propTypes = {
  id: string,
  username: object,
  avatar: object,
  links: array,
  socials: object,
  transparentButtons: bool,
  roundedCorners: bool,
  isLoading: bool,
  hideWatermark: bool
}

export default LinkGalleryContent
