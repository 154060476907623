import React from 'react'
import { string, object, oneOf, oneOfType, node } from 'prop-types'
import classNames from 'classnames'

import './AlertPanel.css'

const AlertPanel = ({ title, message, button, illustration, size, ...rest }) => {
  return (
    <div className='Container--narrow'>
      <div
        className={classNames('AlertPanel', {
          'AlertPanel--sm': size === 'sm',
          'AlertPanel--md': size === 'md',
          'AlertPanel--lg': size === 'lg'
        })}
        {...rest}
      >

        {illustration &&
          <div className='AlertPanel__icon'>
            {illustration}
          </div>
        }

        {title &&
          <div className='AlertPanel__title'>
            {title}
          </div>
        }

        {message &&
          <div className='AlertPanel__message'>
            {message}
          </div>
        }

        {button &&
          <div className='AlertPanel__cta m-t-24'>
            {button}
          </div>
        }
      </div>
    </div>
  )
}

AlertPanel.propTypes = {
  title: oneOfType([string, node]),
  message: oneOfType([string, node]),
  illustration: node,
  button: object,
  size: oneOf(['sm', 'md', 'lg'])
}

AlertPanel.defaultProps = {
  size: 'md'
}

export default AlertPanel
